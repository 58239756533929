:root {
  --footer-nav-active: #2f5c79;
  --footer-nav: #243a48;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-blue {
  background-color: var(--footer-nav);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.gradiant-bg-custom {
  background: linear-gradient(180deg, #22262a 0, #1D6844 180%);
  border: none;
  box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
  opacity: 1;
}


.font-small {
  font-size: 10px;
}

.back-text {
  color: #008EFB;
}

.lay-text {
  color: #EF5480;
}

.filter {
  -webkit-filter: invert(1);
  filter: invert(1);
}


/* For Webkit-based browsers (e.g., Chrome, Safari) */

::-webkit-scrollbar {

  width: 5px;

  height: 4px;

  /* Width of the scrollbar */

}



::-webkit-scrollbar-thumb {

  background-color: #4c555e;

  /* Color of the thumb (dragging part) */

  border-radius: 5px;

  /* Rounded corners of the thumb */

}







/* For Firefox */

/* Note: Firefox uses a different syntax for scrollbar customization */

/* You may need to prefix these styles with -moz- for compatibility */

/* It's important to check the current browser's requirements */



/* Scrollbar track (background) */

scrollbar {

  width: 5px;

  height: 4px;

}



/* Scrollbar thumb (dragging part) */

scrollbar-thumb {

  background-color: #4c555e;

  border-radius: 5px;

}

@media (max-width:767px) {
  .m-header {
    background: linear-gradient(180deg, #22262a 10%, #169c59 100%);
  }
}


/* --------------new-------------- */
.blink-text-animation {
  /* -webkit-animation:new-items 1s linear infinite; */
  animation: new-items 1s linear infinite;
}

@-webkit-keyframes new-items {

  0%,
  100% {
    color: #FFF
  }

  25% {
    color: #FCE181
  }

  50% {
    color: #ED534B
  }

  75% {
    color: #00C9E5
  }
}

@keyframes new-items {

  0%,
  100% {
    color: #FFF
  }

  25% {
    color: #FCE181
  }

  50% {
    color: #ED534B
  }

  75% {
    color: #00C9E5
  }
}

.button.slick-arrow.slick-next {
  right: 0 !important;
  left: auto !important;
  border-radius: 5px 0 0 5px !important;
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px) !important;
}