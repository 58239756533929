@tailwind base;
@tailwind components;
@tailwind utilities;


/* famous blue */
@layer base {
  :root {
    --primary-fifty: 248, 250, 252;
    --primary-one: 241, 245, 249;
    --primary-two: 226, 232, 240;
    --primary-three: 203, 213, 225;
    --primary-four: 148, 163, 184;
    --primary-five: 100, 116, 139;
    --primary-six: 75, 85, 99;
    --primary-seven: 51, 65, 85;
    --primary-eight: 36, 58, 72;
    --primary-nine: 15, 23, 42;
    --secondary-fifty: 248, 250, 252;
    --secondary-one: 241, 245, 249;
    --secondary-two: 226, 232, 240;
    --secondary-three: 203, 213, 225;
    --secondary-four: 148, 163, 184;
    --secondary-five: 100, 116, 139;
    --secondary-six: 71, 85, 105;
    --secondary-seven: 51, 65, 85;
    --secondary-eight: 30, 41, 59;
    --secondary-nine: 15, 23, 42;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-19-7DQk6YvNkeg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-19a7DQk6YvNkeg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-1967DQk6YvNkeg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-19G7DQk6YvNkeg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-1927DQk6YvNkeg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DQk6YvNkeg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQk6YvM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCkYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCAYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCgYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCcYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCsYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCoYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCQYb9lecyU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --theme-color: #22262a;
  --footer-nav-active: #2f5c79;
  --back: #72bbef;
  --back1: #b7d5eb;
  --back2: #d7e8f4;
  --lay: #faa9ba;
  --lay1: #efd3d9;
  --lay2: #f6e6ea;
  --text-color1: #02383f;
  --yellow-1: #ffb80c;
  --even-bg: #f0fffc;
  --black-bg: #000;
  --bg-light: #202020;
  --bg-white: #ffffff;
  --shadow-color:#4c555e;
}

body {
  margin: 0;
  font-family: Roboto Condensed;
  background-color: #0b0d0e;
}

@media (min-width: 1024px) {
   .container {
    max-width: 1440px;
    width: 100%;
    min-width: 1024px;
    padding-left: 15px;
    padding-right: 15px;
}

}

code {
  font-family: Roboto Condensed;
}

.dark {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}


.theme-light {
  background: var(--bg-white);
}

.back {
  background: var(--back);
}

.back1 {
  background: var(--back1);
}

.back2 {
  background: var(--back2);
}

.lay {
  background: var(--lay);
}

.lay1 {
  background: var(--lay1);
}

.lay2 {
  background: var(--lay2);
}

.yellow-1 {
  background: var(--yellow-1);
}

.text-color1 {
  color: var(--text-color1);
}

.bg-btn {
  background-color: #243a48;
}

.theme-color {
  background-color: var(--theme-color);
}
.shadow-color {  box-shadow: 0 0.66px 0 var(--shadow-color);}

.Gray-bg {
  background-color: #bed5d8;
}

.even-bg {
  background: var(--even-bg);
}


.suspended {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(36, 58, 72, .4);
  color: #fff;
  opacity: .8;
  text-transform: capitalize;
  font-size: 11px;
}

.login-bg {
  background-image: url(images/login-bg-2.jpg);
}

.event-bg {
  background-image: url(images/event.webp);
}

.bg-transparent {
  background-color: rgba(0, 0, 0, .73);
}

.bg-gray {
  background-color: #212121;
}


.fancyTab {
  background-color: #e3fcff75;

}

.matchname a {
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  display: block;
}

/* .filter-1 {
  filter: invert(1);
} */

.green-bg {
  background-color: #60ba1e;
}


.game-fancy,
.game-bm {
  font-weight: 900;
  color: #fff;
  font-size: 9px;
  margin-left: 1px;
  border-radius: 0;
  width: 18px;
  height: 18px;
  display: inline-grid;
  text-align: center;
  align-items: center;
}

.game-fancy {
  background: #0A92A5;
  animation-name: fancy;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes fancy {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.4;
    background: lime;
  }

  100% {
    opacity: 1.0;
  }
}

.game-bm {
  background: #226BC3;
  animation-name: bm;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes bm {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.4;
    background: red;
    border-radius: 0;
  }

  100% {
    opacity: 1.0;
  }
}


.match-title {
  background: var(--theme-color);
  position: relative;
}

.match-title:before {
  content: "";
  clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
  width: 18px;
  height: calc(100% + 0px);
  background: linear-gradient(180deg, var(--theme-color) 0, var(--theme-color));
  position: absolute;
  right: -17px;
  top: 0;
}

.active-hl {
  background: var(--theme-color);
  color: #fff;
}

.active-bm {
  background: var(--footer-nav-active);
  color: #fff;
}

